<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					SOFTWARE SERVICE
				</div>
				<div class="cen-msg">
					The TMS cloud management platform can manage various types of
					<br />
					terminals, has a safe and convenient value-added service introduction
					<br />
					mode; provides 7X24 hours of professional after-sales service.
				</div>
			</div>
		</div>
		<div class="page_3">
			<div class="page_2_1_bot">
			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		
		watch: {

		},

		mounted() {

		},
		data() {
			return {
				acctive_cata_id: 0,
				acctive_type_id: 10,

			}
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		methods: {
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 440px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/serves/head3.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.cen-text {
			margin-left: 15%;
			font-size: 60px;
			margin-top: 120px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}


		.cen-msg {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			background: #FFFFFF;
	
			.page_2_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_2_1_top {
				height: 100px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
	
				.menu-title-class {
					cursor: pointer;
					font-size: 16px;
					color: #4e4e4e;
				}
	
	
			}
	
	
	
		}
	
		.page_3 {
			background: #FAFAFA;
			.page_2_1_bot {
				width: 1360px;
				margin: 0 auto;
				height: 2400px;
				object-fit: cover; // 保持图片缩放时候不变形
				background-image: url("../../assets/images/serves/tms.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
	
	
			}
		}
	
	
</style>